<template>
  <div>
      <Tab :items="items" class="my-10" />
      <router-view />
  </div>
</template>

<script>
import Tab from "@/components/atom/Tab";
import data from "@/data/pending-evaluation/data";
export default {
    components: { Tab },
    data() {
    return {
      items: data.feedbackTab
    };
  },
}
</script>